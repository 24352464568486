// 手机号
const phoneReg = /^1[3456789]\d{9}$/
//邮箱
// const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
const regEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
// const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
// 身份证号
const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
export default {
// 电话验证
    validatePhone : function(rule, value, callback) {
        if (value =="") {
            callback()
        }
        if (!phoneReg.test(value)) {
            callback(new Error('请输入正确的手机号码'))
        } else {
            callback()
        }
    },
    validateIdCard : function (rule, value, callback) {
        if (value =="") {
            callback()
        }
        if (!regId.test(value)) {
            callback(new Error('请输入正确的身份证号'))
        } else {
            callback()
        }
    },
    validateEmail: function (rule, value, callback) {
        if (value == "") {
            callback()
        }
        if (!regEmail.test(value)) {
            callback(new Error('请输入正确的邮箱号'))
        } else {
            callback()
        }
    }
}
